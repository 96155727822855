import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SiteLayout from '../../../../layouts/SiteLayout/SiteLayout';
import SiteSection from '../../../../layouts/SiteLayout/SiteSection/SiteSection';
import HeadingSection from '../../../../siteBaseSections/HeadingSection/HeadingSection';
import Advertising from '../../../../shared/Advertising/Advertising';

export function BasePageView(props) {
  const {
    title: defaultPageTitle,
    siteHeader: { meta: { title: defaultTitle } = {} } = {},
    siteContent: {
      data: {
        sections = [],
        details: { pageTitle, titleSize, showPageHeader } = {},
      } = {},
      meta: { title: contentTitle } = {},
    } = {},
  } = props;
  const middleIndex = Math.floor(sections.length / 2);

  return (
    <SiteLayout
      helmet={{
        title: contentTitle || `${defaultPageTitle} | ${defaultTitle}`,
      }}
    >
      {showPageHeader && (
        <HeadingSection
          title={pageTitle || defaultPageTitle}
          titleSize={titleSize}
        />
      )}

      {sections.map((section, index) => (
        <>
          <SiteSection key={section.id} section={section} index={index} />
          {index === middleIndex && (
            <Advertising advSlots={[{ slotId: '728_2' }]} advSize="728_90" />
          )}
        </>
      ))}
      {props.children}
    </SiteLayout>
  );
}

BasePageView.propTypes = {
  siteHeader: PropTypes.shape({
    meta: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  siteContent: PropTypes.shape({
    data: PropTypes.shape({
      sections: PropTypes.arrayOf(PropTypes.object),
    }),
    meta: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
};

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    siteContent: state.siteContent,
  };
}

export default connect(mapStateToProps)(BasePageView);
